import React from "react";
import { Auth } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";
import { Button, Divider, Dropdown } from "semantic-ui-react";

import { addUser, createTodo } from "../graphql/mutations";

import { listTodos } from "../graphql/queries";

const Signup = () => {
  async function addToDo() {
    try {
      const addUser = await API.graphql(
        graphqlOperation(createTodo, { input: { name: "al" } })
      );
      console.log(addUser);
    } catch (error) {
      console.log(error);
    }
  }
  async function listToDo() {
    try {
      const list = await API.graphql(graphqlOperation(listTodos));
      console.log(list.data.listTodos.items);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSubmit() {
    try {
      console.log("trying to add user");
      const email = "al@albuick.com";
      const password = "Topgun85%";

      const revisionData = await API.graphql(
        graphqlOperation(addUser, { email: email, password: password })
      );
      console.log(revisionData);
    } catch (error) {}

    // try {
    //   const response = await Auth.signUp({
    //     username: email,
    //     password: password,
    //     attributes: {
    //       email: email,
    //     },
    //   });
    //   console.log(response);
    //   //await Auth.signIn(formValues.email, formValues.password);
    //   //this.props.authStatus({isAuthenticated: true, isLoading: false});
    // } catch (e) {
    //   alert(e.message);
    //   this.props.authStatus({ isLoading: false });
    // }
  }
  return (
    <div>
      <Button primary onClick={() => handleSubmit()}>
        asd
      </Button>
      <Button secondary onClick={() => addToDo()}>
        Add Todo
      </Button>
      <Button secondary onClick={() => listToDo()}>
        List Todo
      </Button>
    </div>
  );
};

export default Signup;
